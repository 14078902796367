<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <el-row class='table-search'>
          <el-form :inline='true' :model='searchCondition' class='demo-form-inline'>
            <el-form-item label='年份'>
              <el-date-picker
                v-model='searchCondition.year'
                type='year'
                format='yyyy 年'
                value-format='yyyy'
                style='width: 120px'
                placeholder='选择年'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='searchButton'>查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' size='mini' v-if="userPermissions.indexOf('plc_edit')>-1"
                         @click='handleAdd'>
                <i class='el-icon-plus'></i> 新建
              </el-button>
              <!--              <el-button type="danger" size="mini" icon="el-icon-delete" :disabled="multiple" @click="handleDelete">删除-->
              <!--              </el-button>-->
            </el-form-item>
          </el-form>
        </el-row>
        <div class='default-table'>
          <ApeTable ref='apeTable' :data='dataList' :columns='columns' :loading='loadingStatus'
                    :pagingData='pagingData'
                    @switchPaging='switchPaging' highlight-current-row border>
            <el-table-column label='序号' align='center' width='80' slot='first-column'>
              <template slot-scope='{$index}'>
                {{ offset + $index + 1 }}
              </template>
            </el-table-column>
            <!--            <el-table-column label='ID' prop='id' width='100' slot='second-column'></el-table-column>-->
            <!--            <el-table-column label="提报时间" align="center" width="120" slot="second-column">-->
            <!--              <template slot-scope="scope">-->
            <!--                <span>{{ scope.row.year || '/' }} 年 {{ scope.row.month || '/' }} 月</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column label='刊例标题' align='center' min-width='200' slot='second-column'
                             :show-overflow-tooltip='true'>
              <template slot-scope='scope'>
                <el-link type='primary' @click='jumpTo(scope.row)'>{{ scope.row.title || '/' }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label='有效期' align='center' min-width='200' slot='second-column'
                             :show-overflow-tooltip='true'>
              <template slot-scope='scope'>
                <span>{{ scope.row.validity_date || '/' }} ~ {{ scope.row.expiry_date || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='操作' width='180' align='center' fixed='right'>
              <template slot-scope='{row,$index}'>
                <div style='display: flex;flex-direction: row;justify-content: space-evenly;gap: 5px'>
                  <el-button size='mini' type='text' icon='el-icon-edit' @click='handleUpdate(row)'
                             v-if="userPermissions.indexOf('plc_edit')>-1">
                    修改
                  </el-button>
                  <el-button size='mini' type='text' icon='el-icon-delete' @click='handleDelete(row)'
                             v-if="userPermissions.indexOf('plc_delete')>-1">删除
                  </el-button>

                  <export-btn size='mini' type='text' :ref='`refExportLink_${$index}`'
                              :can-export="userPermissions.indexOf('plc_export')>-1"
                              :lint-title='`导出`' :title='`${row.year}年${row.month}月刊例`'
                              @export='exportData(row,$index)'
                              class-name='export-btn'
                              style='display: inline' />
                </div>

              </template>
            </el-table-column>
          </ApeTable>
        </div>
      </div>

    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'PublicationIndex',
  components: { ExportBtn, ApeTable, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      loadingStatus: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      searchCondition: { year: '' },
      dataList: [],
      columns: [
        // {title: '提报时间', value: 'date_alias', width: 120},
        // {title: '刊例标题', value: 'title', width: 200},
        // {title: '提报数量', value: 'items_count', width: 100},
        // {title: '状态', value: 'status_alias', width: 100},
        // {title: '有效期', value: 'period_validity', width: 120},
        { title: '提报截止时间', value: 'deadline', width: 120 },
        { title: '创建时间', value: 'created_at', width: 120 },
        { title: '最后修改时间', value: 'updated_at', width: 120 }
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 30
        // offset: 0,
      },
      // 分页的offset,序号列使用
      offset: 0
    }
  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 2) {
        this.getList()
      }
    }
  },
  methods: {
    searchButton() {
      //查询
      // this.$notify.info('查询')
      this.getList()
    },
    handleAdd() {
      // this.$notify.info('新建刊例')
      this.$router.push({ name: 'plc_create' })
    },
    handleUpdate(row) {
      // this.$notify.info('新建刊例')
      this.$router.push({ name: 'plc_edit', params: { id: row.id } })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该月刊例, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.delPublicationMonthInfo(row.id)
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    switchPaging() {
      this.getList()
    },
    async getList(type) {
      this.loadingStatus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getPublicationMonthList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.loadingStatus = false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }

      return condition
    },
    jumpTo(row) {
      //调整
      this.$router.push({ name: 'plc_list', params: { year: row.year, month: row.month } })
    },
    //导出表格
    // eslint-disable-next-line no-unused-vars
    async exportData(row, index) {
      try {
        // eslint-disable-next-line no-unused-vars
        let date = new Date()
        //获取数据
        let data = await this.$api.getExportPublicationAllData(row.year, row.month)
        // // 生成excel
        let response = await this.$api.downloadPublicationExcelByData(data)
        let name = `【缇苏】${row.year}年${row.month}月刊例（含税）-${date.getMonth() + 1}.${date.getDate()}`
        this.$refs[`refExportLink_${index}`].exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }
    }
  },
  mounted() {
    // console.log(this.$route.matched.length)
    if (this.$route.matched.length === 2)
      this.getList()
  },
  created() {
    let date = new Date()
    this.searchCondition.year = date.getFullYear() + ''
  }
}
</script>

<style scoped>

</style>
